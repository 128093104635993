<template>
<b-tab :title="$t('prices_form')" active>
  <b-row>
    <b-col v-if="form.groupId === ''" sm="3">
      <div class="form-group">
        <label for="group">{{ $t('discount_name') }}</label>
        <input type="text" class="form-control form-control-sm" v-model="form.name" @keypress.enter="addGroup">
      </div>
      <div class="">
        <b-button size="sm" variant="success" @click="addGroup">{{ $t('create') }}</b-button>
      </div>
    </b-col>
    <b-col sm="3">
      <div class="form-group">
        <label for="group">{{ $t('discount_group') }}</label>
        <select class="form-control form-control-sm" v-model="form.groupId" @change="setDiscountGroup">
          <option value=""> -- {{ $t('select_discount') }} -- </option>
          <option v-for="(item, index) in discounts" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div class="">
        <b-button v-if="form.groupId !== ''" size="sm" variant="success" @click="changeDiscountGroups">{{ $t('edit') }}</b-button>
      </div>
    </b-col>
  </b-row>
  <hr>
  <b-row>
    <b-col sm="6">
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th>Група/Характеристика</th>
            <th>Тип</th>
            <th>Скидка/Націнка</th>
            <th>Значення</th>
          </tr>
        </thead>
        <tbody v-if="groups">
          <template v-for="(item, idx) in groups">
          <tr :class="{ 'text-success': item.valid }">
            <td class="text-center"><strong>{{ item.name }}</strong></td>
            <td>
              <select class="form-control form-control-sm" v-model="item.type" @change="checkValidation(item.id, idx)" :class="{ 'is-invalid': errorsGroup[item.id] && !errorsGroup[item.id]['type'] }">
                <option value=""> -- </option>
                <option value="percent">%</option>
                <option value="sum">Сума</option>
              </select>
            </td>
            <td>
              <select class="form-control form-control-sm" v-model="item.discountType" @change="checkValidation(item.id, idx)" :class="{ 'is-invalid': errorsGroup[item.id] && !errorsGroup[item.id]['discountType'] }">
                <option value=""> -- </option>
                <option value="discount">Скидка</option>
                <option value="markup">Націнка</option>
              </select>
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" v-model.number="item.value" @input="checkValidation(item.id, idx)" :class="{ 'is-invalid': errorsGroup[item.id] && !errorsGroup[item.id]['value'] }">
            </td>
          </tr>
          <tr v-for="val in item.items" :class="{ 'text-success': val.valid }">
            <td class="text-right">{{ val.name }}</td>
            <td>
              <select class="form-control form-control-sm" v-model="val.type" @change="checkValidationChar(item.id, val.id)" :class="{ 'is-invalid': (errorsCharsGroup[item.id] && errorsCharsGroup[item.id][val.id]) && !errorsCharsGroup[item.id][val.id]['type'] }">
                <option value=""> -- </option>
                <option value="percent">%</option>
                <option value="sum">Сума</option>
              </select>
            </td>
            <td>
              <select class="form-control form-control-sm" v-model="val.discountType" @change="checkValidationChar(item.id, val.id)" :class="{ 'is-invalid': (errorsCharsGroup[item.id] && errorsCharsGroup[item.id][val.id]) && !errorsCharsGroup[item.id][val.id]['discountType'] }">
                <option value=""> -- </option>
                <option value="discount">Скидка</option>
                <option value="markup">Націнка</option>
              </select>
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" v-model="val.value" @input="checkValidationChar(item.id, val.id)" :class="{ 'is-invalid': (errorsCharsGroup[item.id] && errorsCharsGroup[item.id][val.id]) && !errorsCharsGroup[item.id][val.id]['value'] }">
            </td>
          </tr>
          </template>
        </tbody>
      </table>
    </b-col>
    <b-col sm="6">
      <table v-if="users" class="table table-sm table-bordered">
        <thead>
          <tr>
            <th class="text-center">Група</th>
            <th class="text-center">Користувач</th>
            <th>Група скидки</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in users" :key="item.id">
            <td class="text-center">
              <p v-for="role in item.roles" :key="role.id">
              <span v-if="role.name === 'manager'">Менеджер</span>
              <span v-if="role.name === 'dropshipper'">Дропшипер</span>
              </p>
            </td>
            <td class="text-center">{{ item.lastName }} {{ item.firstName }}</td>
            <td>
              <select class="form-control form-control-sm" @change="setDiscountGroupToUser(item.id, $event)" v-model="item.discountGroupsId">
                <option v-for="d in discounts" :key="d.id" :value="d.id">{{ d.name }}</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Pagination -->
    <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
    </b-col>
    <b-col v-if="discounts" sm="12">
      <h5>Групи скидок</h5>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Група</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in discounts" :key="item.id">
            <td>{{ item.name }}</td>
            <td class="text-center">
              <b-button v-if="idx !== 0" size="sm" variant="outline-danger" @click="removeDiscountGroup(item.id)"><fa icon="times"/></b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</b-tab>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data: () => ({
      form: {
        name: '',
        groupId: ''
      },
      discounts: null,
      users: null,
      currentPage: 1,
      perPage: 15,
      totalItems: 0,
      urlParams: {},
      urlParamsGroups: {},
      groups: null,
      errorsGroup: {},
      errorsCharsGroup: {}
    }),
    mounted () {
      this.getGroups()
      this.getUsers()
      this.getDiscountGroups()
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    methods: {
      setDiscountGroup (e) {
        const val = e.target.value
        this.urlParamsGroups['discountGroupsId'] = val
        this.getGroups()
      },
      //discount-groups/groups
      async getGroups () {
        const params = this.buildQueryGroups() !== '' ? '?' + this.buildQueryGroups() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.get(apiUrl + 'discount-groups/groups' + params).then(resp => {
          if (resp.data) {
            this.groups = resp.data
          }
        })
      },
      async getUsers () {
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.get(apiUrl + 'discount-groups/users?page=' + this.currentPage + params).then(resp => {
          if (resp.data) {
            const data = resp.data.data
            const meta = resp.data.meta
            this.users = data
            this.currentPage = meta.pagination.current_page
            this.perPage = meta.pagination.per_page
            this.totalItems = meta.pagination.total
          }
        })
      },
      async setDiscountGroupToUser (userId, e)
      {
        const val = e.target.value
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'discount-groups/users', {
          userId: userId,
          discountGroupsId: val
        }).then(resp => {
          if (resp.data.status) {
            this.getUsers()
          }
        })
      },
      async addGroup () {
        if (this.form.name !== '') {
          const arr = []
          this.groups.filter(el => {
            if (typeof el.valid !== 'undefined') {
              arr.push({
                id: el.id,
                groupId: el.id,
                editId: el.editId,
                product: el.productName,
                type: el.type,
                discountType: el.discountType,
                value: Number.parseInt(el.value),
                typeOfCategory: el.typeOfCategory
              })
            }
            el.items.forEach(i => {
              if (typeof i.valid !== 'undefined' && i.valid) {
                arr.push({
                  id: null,
                  groupId: el.id,
                  editId: el.editId,
                  product: i.productName,
                  type: i.type,
                  discountType: i.discountType,
                  value: Number.parseInt(i.value),
                  typeOfCategory: i.typeOfCategory
                })
              }
            })
          })
          const obj = {
            groupName: this.form.name,
            values: arr
          }
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'discount-groups', obj).then(resp => {
            if (resp.data.status) {
              this.getDiscountGroups()
              this.getGroups()
              this.form.name = ''
            }
          })
        } else {
          Swal.fire({
            icon: 'danger',
            title: 'Помилка',
            text: 'Потрібно вказати імя групи',
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
        }
      },
      async changeDiscountGroups () {
        if (this.form.groupId) {
          const arr = []
          this.groups.filter(el => {
            if (typeof el.valid !== 'undefined' && el.valid) {
              arr.push({
                id: el.id,
                groupId: el.id,
                editId: el.editId,
                product: el.productName,
                type: el.type,
                discountType: el.discountType,
                value: Number.parseInt(el.value),
                typeOfCategory: el.typeOfCategory
              })
            }
            el.items.forEach(i => {
              if (typeof i.valid !== 'undefined' && i.valid) {
                arr.push({
                  id: null,
                  groupId: el.id,
                  editId: el.editId,
                  product: i.productName,
                  type: i.type,
                  discountType: i.discountType,
                  value: Number.parseInt(i.value),
                  typeOfCategory: i.typeOfCategory
                })
              }
            })
          })
          const obj = {
            groupId: this.form.groupId,
            values: arr
          }
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.put(apiUrl + 'discount-groups', obj).then(resp => {
            if (resp.data.status) {
              Swal.fire({
                icon: 'info',
                title: 'Інформація',
                text: 'Збережено',
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
              this.getGroups()
            }
          })
        } else {
          Swal.fire({
            icon: 'danger',
            title: 'Помилка',
            text: 'Потрібно вказати вибрати групу',
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
        }
      },
      async getDiscountGroups () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.get(apiUrl + 'discount-groups').then(resp => {
          if (resp.data) {
            this.discounts = resp.data.discount_groups
          }
        })
      },
      async removeDiscountGroup (id) {
        if (confirm('Ви точно хочете видалити?')) {
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.delete(apiUrl + 'discount-groups/' + id).then(resp => {
            if (resp.data.status) {
              this.getDiscountGroups()
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Помилка',
                text: resp.data.msg,
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
            }
          })
        }
      },
      checkValidation (groupId, index) {
        const group = this.groups.find(g => g.id === groupId)
        if (typeof group !== 'undefined') {
          const arr = ['type', 'discountType', 'value']
          const e = {}
          arr.filter(a => {
            if (group[a] === '') {
              e[a] = false
            } else {
              e[a] = true
            }
          })
          if (e) {
            if (this.errorsGroup[groupId]) {
              const c = Object.values(e).every(item => item === false)
              if (c) {
                delete this.errorsGroup[groupId]
              } else {
                this.errorsGroup[groupId] = e
              }
            } else {
              this.errorsGroup[groupId] = e
            }
          }
          const validGroup = Object.values(e).every(item => item)
          group.valid = validGroup
        }
      },
      checkValidationChar (groupId, id) {
        const group = this.groups.find(g => g.id === groupId)
        if (typeof group !== 'undefined') {
          const char = group.items.find(i => i.id === id)
          if (typeof char !== 'undefined') {
            const arr = ['type', 'discountType', 'value']
            const e = {}
            arr.filter(a => {
              if (char[a] === '') {
                e[a] = false
              } else {
                e[a] = true
              }
            })
            if (e) {
              if (this.errorsCharsGroup[groupId]) {
                const c = Object.values(e).every(item => item === false)
                if (c) {
                  delete this.errorsCharsGroup[groupId][id]
                  if (Object.keys(this.errorsCharsGroup[groupId]).length === 0) {
                    delete this.errorsCharsGroup[groupId]
                  }
                } else {
                  const obj = {}
                  obj[id] = e
                  this.errorsCharsGroup[groupId] = obj
                }
              } else {
                const obj = {}
                obj[id] = e
                this.errorsCharsGroup[groupId] = obj
              }
            }
            const validGroup = Object.values(e).every(item => item)
            char.valid = validGroup
          }
        }
      },
      buildQueryGroups () {
        return Object.entries(this.urlParamsGroups).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
    },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getUsers()
      }
    }
  }
}
</script>
